import {Injectable} from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Observable, throwError, BehaviorSubject ,catchError, of } from "rxjs";
import {AuthService, User} from "@core";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, Router} from "@angular/router";
import {ShowNotification} from "../../pages/service/showNotification.service";


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  ctr = 0
  constructor(public authService: AuthService,
              private authenticationService: AuthService,
              // private _snackBar: MatSnackBar,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private showNotification : ShowNotification) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(x=> this.handleAuthError(x)));
  }


  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if(err && err.status === 401  && this.ctr != 1){
      this.ctr++
      // this.authService.refreshToken(localStorage.getItem('refreshToken')).subscribe({
      //   next: (x:any) => {
      //     debugger
      //     console.log("#### successful #### " + this.router)
      //     console.log("#### activatedRoute #### " + this.activatedRoute)
      //     this._snackBar.open("We refreshed the token now do again what u were trying to do");
      //     this.router.navigateByUrl('/');
      //   },
      //   error: (err:any) => {
      //     debugger
      //     console.log("#### error #### " + err)
      //     this._snackBar.open("Refresh token was expired. Please make a new signin request");
      //     this.router.navigateByUrl('/');
      //   }
      // });

      //notRefresh
      this.showNotification.getShowNotification( 'snackbar-danger',"Refresh token was expired. Please make a new signin request" );
      this.router.navigateByUrl('/');
      return of("Attempting to Refresh Tokens");
    }
    else{
      this.ctr = 0
      return throwError(() => new Error("Non Authenticationn Error"));
    }

  }

}


export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: TokenInterceptor,
  multi: true
};


