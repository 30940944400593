
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpInterceptor} from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import {ProvincesRes} from "../../pages/models/provincesRes";
import { throwError } from 'rxjs';
import {RoleTypeRes} from "../../pages/models/roleTypeRes";

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private currentTokenSubject: BehaviorSubject<User>;
  public currentToken: Observable<User>;

  public reqHeader: any;

  constructor(private http: HttpClient ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject.asObservable();


    //token
    this.currentTokenSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('token') || '{}')
    );
    this.currentToken = this.currentTokenSubject.asObservable();


  }

  public getHeader () {
    const currentUser = this.currentTokenValue;
    if (currentUser && currentUser.token) {
      this.reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`
      });
      return  this.reqHeader;
    }
  }


  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentTokenValue(): User {
    return this.currentTokenSubject.value;
  }


  login(userName: string, password: string, roleTest: string) {

    return this.http
      .post<User>(`${environment.apiUrl}/v1/user/login`, {userName:userName,password:password}, )
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          console.log("response login  : " + JSON.stringify(user));
          const result = this.decodedToken(user.token);
          //  result.role = roleTest; //pading

          if (result!=null) {
            result.role = result.role[0]; // เอาตัวแรกเสมอ
          }

          localStorage.setItem('currentUser', JSON.stringify(result));
          localStorage.setItem('token', JSON.stringify(user));
          localStorage.setItem('lang', 'th');
          localStorage.setItem('refreshToken', user.refreshToken);

          this.currentUserSubject.next(result);
          this.currentTokenSubject.next(user);
          return user;
        })
      );
  }


  refreshToken(refreshToken :any){
    return this.http.get<User>(`${environment.apiUrl}/v1/user/refresh-token/`+refreshToken)
      .pipe(
        map((user) => {
          console.log("response login  : " + JSON.stringify(user));
          const result = this.decodedToken(user.token);

          if (result!=null) {
            result.role = result.role[0]; // เอาตัวแรกเสมอ
          }

          localStorage.setItem('currentUser', JSON.stringify(result));
          localStorage.setItem('token', JSON.stringify(user));
          localStorage.setItem('lang', 'th');
          localStorage.setItem('refreshToken', user.refreshToken);

          this.currentUserSubject.next(result);
          this.currentTokenSubject.next(user);
          return user;
        } )
    );
  }


  logout() {
    localStorage.clear();
    this.currentUserSubject.next(this.currentUserValue);
    this.currentTokenSubject.next(this.currentTokenValue);

    localStorage.removeItem('lang');
    localStorage.removeItem('refreshToken');

    return of({ success: false });
  }

  decodedToken(token: string) {
    console.log("AuthService.decodedToken()...");
    const helper = new JwtHelperService();
    const decoded = helper.decodeToken(token);

    return decoded;
  }

  getJwtToken() {
    return localStorage.getItem('token') as string
  }
}
