import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },

  // Admin Modules
  // {
  //   path: '',
  //   title: 'MENUITEMS.DASHBOARD.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'space_dashboard',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/dashboard/main',
  //       title: 'MENUITEMS.DASHBOARD.LIST.DASHBOARD1',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/dashboard/dashboard2',
  //       title: 'MENUITEMS.DASHBOARD.LIST.DASHBOARD2',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/dashboard/doctor-dashboard',
  //       title: 'MENUITEMS.DASHBOARD.LIST.DOCTOR-DASHBOARD',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/dashboard/patientes-dashboard',
  //       title: 'MENUITEMS.DASHBOARD.LIST.PATIENT-DASHBOARD',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.APPOINTMENTS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'assignment',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/appointment/viewAppointment',
  //       title: 'MENUITEMS.APPOINTMENTS.LIST.VIEW-APPOINTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/appointment/bookAppointment',
  //       title: 'MENUITEMS.APPOINTMENTS.LIST.BOOK-APPOINTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/appointment/edit-ppointment',
  //       title: 'MENUITEMS.APPOINTMENTS.LIST.EDIT-APPOINTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.DOCTORS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'supervised_user_circle',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/doctors/allDoctors',
  //       title: 'MENUITEMS.DOCTORS.LIST.ALL-DOCTOR',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/doctors/add-doctor',
  //       title: 'MENUITEMS.DOCTORS.LIST.ADD-DOCTOR',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/doctors/edit-doctor',
  //       title: 'MENUITEMS.DOCTORS.LIST.EDIT-DOCTOR',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/doctors/doctor-profile',
  //       title: 'MENUITEMS.DOCTORS.LIST.PROFILE',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.STAFF.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'people_alt',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/staff/all-staff',
  //       title: 'MENUITEMS.STAFF.LIST.ALL-STAFF',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/staff/add-staff',
  //       title: 'MENUITEMS.STAFF.LIST.ADD-STAFF',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/staff/edit-staff',
  //       title: 'MENUITEMS.STAFF.LIST.EDIT-STAFF',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/staff/staff-profile',
  //       title: 'MENUITEMS.STAFF.LIST.PROFILE',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.PATIENTS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'face',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/patients/all-patients',
  //       title: 'MENUITEMS.PATIENTS.LIST.ALL-PATIENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/patients/add-patientes',
  //       title: 'MENUITEMS.PATIENTS.LIST.ADD-PATIENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/patients/edit-patientes',
  //       title: 'MENUITEMS.PATIENTS.LIST.EDIT-PATIENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/patients/patientes-profile',
  //       title: 'MENUITEMS.PATIENTS.LIST.PROFILE',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.ROOMS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'hotel',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/room/all-rooms',
  //       title: 'MENUITEMS.ROOMS.LIST.ALLOTED-ROOMS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/room/add-allotment',
  //       title: 'MENUITEMS.ROOMS.LIST.NEW-ALLOTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/room/edit-allotment',
  //       title: 'MENUITEMS.ROOMS.LIST.EDIT-ALLOTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.BILLING.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'monetization_on',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/billing/bill-list',
  //       title: 'MENUITEMS.BILLING.LIST.BILL-LIST',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/billing/add-bill',
  //       title: 'MENUITEMS.BILLING.LIST.ADD-BILL',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/billing/invoice',
  //       title: 'MENUITEMS.BILLING.LIST.INVOICE',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.RECORDS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'list_alt',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/records/birth',
  //       title: 'MENUITEMS.RECORDS.LIST.BIRTH-RECORDS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/records/death',
  //       title: 'MENUITEMS.RECORDS.LIST.DEATH-RECORDS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.AMBULANCE.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'airport_shuttle',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/ambulance/call-list',
  //       title: 'MENUITEMS.AMBULANCE.LIST.AMBULANCE-CALL-LIST',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/ambulance/list',
  //       title: 'MENUITEMS.AMBULANCE.LIST.AMBULANCE-LIST',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.PHARMACY.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'local_pharmacy',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/pharmacy/medicine-list',
  //       title: 'MENUITEMS.PHARMACY.LIST.MEDICINE-LIST',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/pharmacy/add-medicine',
  //       title: 'MENUITEMS.PHARMACY.LIST.ADD-MEDICINE',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.DEPARTMENTS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'group_work',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/departments/department-list',
  //       title: 'MENUITEMS.DEPARTMENTS.LIST.DEPARTMENT-LIST',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/departments/add-department',
  //       title: 'MENUITEMS.DEPARTMENTS.LIST.ADD-DEPARTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.INVENTORY.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'shopping_bag',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/admin/inventory/item-stock-list',
  //       title: 'MENUITEMS.INVENTORY.LIST.ITEM-STOCK-LIST',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/admin/inventory/issued-items',
  //       title: 'MENUITEMS.INVENTORY.LIST.ISSUED-ITEMS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },

  // // Doctor Modules
  // {
  //   path: '/doctor/dashboard',
  //   title: 'MENUITEMS.DOCTOR.DASHBOARD',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'space_dashboard',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: '/doctor/appointments',
  //   title: 'MENUITEMS.DOCTOR.APPOINTMENTS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'assignment',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: '/doctor/doctors',
  //   title: 'MENUITEMS.DOCTOR.DOCTORS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'supervised_user_circle',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: '/doctor/patients',
  //   title: 'MENUITEMS.DOCTOR.PATIENTS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'face',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: '/doctor/settings',
  //   title: 'MENUITEMS.DOCTOR.SETTINGS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'settings',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: '/apps/chat',
  //   title: 'MENUITEMS.DOCTOR.CHAT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'chat',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Doctor'],
  //   submenu: [],
  // },

  // // Patient Modules
  // {
  //   path: '/patientes/dashboard',
  //   title: 'MENUITEMS.PATIENT.DASHBOARD',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'space_dashboard',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Patient'],
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'MENUITEMS.PATIENT.APPOINTMENTS.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'assignment',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Patient'],
  //   submenu: [
  //     {
  //       path: '/patientes/appointments/book',
  //       title: 'MENUITEMS.PATIENT.APPOINTMENTS.LIST.BOOK-APPOINTMENT',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/patientes/appointments/today',
  //       title: 'MENUITEMS.PATIENT.APPOINTMENTS.LIST.TODAY',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/patientes/appointments/upcoming',
  //       title: 'MENUITEMS.PATIENT.APPOINTMENTS.LIST.UPCOMING',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/patientes/appointments/past',
  //       title: 'MENUITEMS.PATIENT.APPOINTMENTS.LIST.PAST',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '/patientes/prescriptions',
  //   title: 'MENUITEMS.PATIENT.PRESCRIPTIONS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'receipt_long',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Patient'],
  //   submenu: [],
  // },
  // {
  //   path: '/patientes/records',
  //   title: 'MENUITEMS.PATIENT.MEDICAL-RECORD',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'restore_page',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Patient'],
  //   submenu: [],
  // },
  // {
  //   path: '/patientes/billing',
  //   title: 'MENUITEMS.PATIENT.BILLING',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'receipt',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Patient'],
  //   submenu: [],
  // },
  // {
  //   path: '/apps/chat',
  //   title: 'MENUITEMS.PATIENT.CHAT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'chat',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Patient'],
  //   submenu: [],
  // },
  // {
  //   path: '/patientes/settings',
  //   title: 'MENUITEMS.PATIENT.SETTINGS',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'settings',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Patient'],
  //   submenu: [],
  // },


  //*************************************** admines *************************************
  { //พนักงาน
    path: '/admines/table-employee',
    title: 'MENUITEMS.MENU_EMPPLOYEE.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },

  {//แพทย์
    path: '/admines/table-doctores',
    title: 'MENUITEMS.MENU_DOCTOR.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'account_circle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Admin'],
    submenu: [],
  },


  //*************************************** doctores *************************************
  {//แพทย์
    path: '/doctores/get-case',
    title: 'MENUITEMS.MENU_DOCTORGETCASE.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'people_alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-green sidebar-badge float-end',
    role: ['Doctor'],
    submenu: [],
  },
  {//รายงาน หมอ
    path: '',
    title: 'MENUITEMS.PATIENT_MENU_REPORT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Doctor'],
    submenu: [
      {
        path: '/doctores/rpt-05',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-05',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Doctor'],
        submenu: [],
      },

    ],
  },

  //*************************************** employee *************************************
  // { //หน้าหลัก
  //   path: '/employee/main',
  //   title: 'MENUITEMS.DASHBOARD.TEXT',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'home',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Employee'],
  //   submenu: [],
  // },
  {//คนไข้
    path: '/employee/patient',
    title: 'MENUITEMS.PATIENT_MENU.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'face',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Employee'],
    submenu: [],
  },
  {//นัดหมาย
    path: '/employee/appoinment',
    title: 'MENUITEMS.PATIENT_MENU_APPOINTMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'event_note',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Employee'],
    submenu: [],
  },
  {//ส่งเคสแพทย์
    path: '/employee/send-case',
    title: 'MENUITEMS.PATIENT_MENU_CASE_JOB.LIST.VIEW-SEND-CASE',
    iconType: 'material-icons-two-tone',
    icon: 'perm_contact_calendar',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Employee'],
    submenu: [],
  },

  {//ชำระค่าบริการ
    path: '/employee/payment',
    title: 'MENUITEMS.PATIENT_MENU_PAYMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'payment',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-green sidebar-badge float-end',
    role: ['Employee'],
    submenu: [],
  },
  {//ตรวจสอบสถานะ
    path: '/employee/check-treatment',
    title: 'MENUITEMS.CHECK_MENU_TREATMENT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'add_business',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-green sidebar-badge float-end',
    role: ['Employee'],
    submenu: [],
  },
  {//ส่งแลป
    path: '/employee/send-lab',
    title: 'MENUITEMS.PATIENT_MENU_CASE_JOB.LIST.VIEW-SEND-LAB',
    iconType: 'material-icons-two-tone',
    icon: 'airport_shuttle',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: 'badge bg-green sidebar-badge float-end',
    role: ['Employee'],
    submenu: [],
  },

  {//เคส
    path: '',
    title: 'MENUITEMS.PATIENT_MENU_CASE_JOB.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'fact_check',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Employee'],
    submenu: [
      {
        path: '/employee/case-job',
        title: 'MENUITEMS.PATIENT_MENU_CASE_JOB.LIST.VIEW-CASE',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {//โปรโมชั่น
        path: '/employee/promotion',
        title: 'MENUITEMS.PATIENT_MENU_PROMOTION.TEXT',
        iconType: '',
        icon: 'perm_contact_calendar',
        class: '',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Employee'],
        submenu: [],
      },

    ],
  },
  {//ห้องรักษา
    path: '/employee/room',
    title: 'MENUITEMS.PATIENT_MENU_ROOM.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'assignment',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Employee'],
    submenu: [],
  },
  {//ข้อมูลสินค้า
    path: '',
    title: 'MENUITEMS.PRODUCT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'shopping_cart',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Employee'],
    submenu: [
      {
        path: '/employee/sell-product',
        title: 'MENUITEMS.PRODUCT.LIST.VIEW-SELL-IN-SHOP-PRODUCT',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/sell-product-list',
        title: 'MENUITEMS.PRODUCT.LIST.VIEW-SELL-IN-SHOP-PRODUCT-LIST',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/product',
        title: 'MENUITEMS.PRODUCT.LIST.VIEW-STOCK',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },

    ],
  },
  //รายงาน พนักงาน
  {
    path: '',
    title: 'MENUITEMS.PATIENT_MENU_REPORT.TEXT',
    iconType: 'material-icons-two-tone',
    icon: 'list_alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['Employee'],
    submenu: [
      {
        path: '/employee/rpt-01',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-01',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/rpt-02',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-02',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/rpt-03',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-03',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/rpt-04',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-04',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/rpt-04-1',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-04-1',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/rpt-04-2',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-04-2',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
      {
        path: '/employee/rpt-05',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-05',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['Employee'],
        submenu: [],
      },
      {
        path: '/employee/rpt-06',
        title: 'MENUITEMS.PATIENT_MENU_REPORT.LIST.RPT-06',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: [''],
        submenu: [],
      },
    ],
  },



  // Common Modules
  // {
  //   path: '',
  //   title: 'Apps',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin', 'Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: 'calendar',
  //   title: 'Calendar',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'event_note',
  //   class: '',
  //   groupTitle: false,
  //   badge: 'New',
  //   badgeClass: 'badge bg-blue sidebar-badge float-end',
  //   role: ['Admin', 'Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: 'task',
  //   title: 'Task',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'fact_check',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin', 'Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: 'contacts',
  //   title: 'Contacts',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'contacts',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin', 'Doctor'],
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'Email',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'email',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin', 'Doctor'],
  //   submenu: [
  //     {
  //       path: '/email/inbox',
  //       title: 'Inbox',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/email/compose',
  //       title: 'Compose',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/email/read-mail',
  //       title: 'Read Email',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'More Apps',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'stars',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '4',
  //   badgeClass: 'badge bg-orange sidebar-badge float-end',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/apps/chat',
  //       title: 'Chat',
  //       iconType: 'material-icons-two-tone',
  //       icon: 'chat',
  //       class: '',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/apps/contact-grid',
  //       title: 'Contact Grid',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/apps/dragdrop',
  //       title: 'Drag & Drop',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/apps/support',
  //       title: 'Support',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Widgets',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'widgets',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/widget/chart-widget',
  //       title: 'Chart Widget',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/widget/data-widget',
  //       title: 'Data Widget',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Components',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [],
  // },
  // {
  //   path: '',
  //   title: 'User Interface (UI)',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'dvr',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/ui/alerts',
  //       title: 'Alerts',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/badges',
  //       title: 'Badges',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/chips',
  //       title: 'Chips',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/models',
  //       title: 'Modal',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/buttons',
  //       title: 'Buttons',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/expansion-panel',
  //       title: 'Expansion Panel',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/bottom-sheet',
  //       title: 'Bottom Sheet',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/dialogs',
  //       title: 'Dialogs',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/cards',
  //       title: 'Cards',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/labels',
  //       title: 'Labels',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/list-group',
  //       title: 'List Group',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/snackbar',
  //       title: 'Snackbar',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/preloaders',
  //       title: 'Preloaders',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/progressbars',
  //       title: 'Progress Bars',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/tabs',
  //       title: 'Tabs',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/typography',
  //       title: 'Typography',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/ui/helper-classes',
  //       title: 'Helper Classes',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Forms',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'subtitles',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/forms/form-controls',
  //       title: 'Form Controls',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/advance-controls',
  //       title: 'Advanced Controls',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/form-example',
  //       title: 'Form Examples',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/form-validation',
  //       title: 'Form Validation',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/wizard',
  //       title: 'Form Wizard',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/forms/editors',
  //       title: 'Editors',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Tables',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'view_list',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/tables/basic-tables',
  //       title: 'Basic Tables',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/tables/material-tables',
  //       title: 'Material Tables',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/tables/ngx-datatable',
  //       title: 'ngx-datatable',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Charts',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'insert_chart',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '7',
  //   badgeClass: 'badge bg-green sidebar-badge float-end',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/charts/echart',
  //       title: 'Echart',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/apex',
  //       title: 'Apex',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/chartjs',
  //       title: 'ChartJS',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/ngx-charts',
  //       title: 'Ngx-Charts',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/charts/gauge',
  //       title: 'Gauge',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Timeline',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'amp_stories',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/timeline/timeline1',
  //       title: 'Timeline 1',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/timeline/timeline2',
  //       title: 'Timeline 2',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Icons',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'eco',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/icons/material',
  //       title: 'Material Icons',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/icons/font-awesome',
  //       title: 'Font Awesome',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Authentication',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'supervised_user_circle',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/authentication/signin',
  //       title: 'Sign In',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/signup',
  //       title: 'Sign Up',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/forgot-password',
  //       title: 'Forgot Password',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/locked',
  //       title: 'Locked',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/page404',
  //       title: '404 - Not Found',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/authentication/page500',
  //       title: '500 - Server Error',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Extra Pages',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'description',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/extra-pages/profile',
  //       title: 'Profile',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/pricing',
  //       title: 'Pricing',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/invoice',
  //       title: 'Invoice',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/faqs',
  //       title: 'Faqs',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/extra-pages/blank',
  //       title: 'Blank Page',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Maps',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'map',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/maps/google',
  //       title: 'Google Map',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: '',
  //   title: 'Multi level Menu',
  //   iconType: 'material-icons-two-tone',
  //   icon: 'slideshow',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['Admin'],
  //   submenu: [
  //     {
  //       path: '/multilevel/first1',
  //       title: 'First',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //     {
  //       path: '/',
  //       title: 'Second',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-sub-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [
  //         {
  //           path: '/multilevel/secondlevel/second1',
  //           title: 'Second 1',
  //           iconType: '',
  //           icon: '',
  //           class: 'ml-menu2',
  //           groupTitle: false,
  //           badge: '',
  //           badgeClass: '',
  //           role: [''],
  //           submenu: [],
  //         },
  //         {
  //           path: '/',
  //           title: 'Second 2',
  //           iconType: '',
  //           icon: '',
  //           class: 'ml-sub-menu2',
  //           groupTitle: false,
  //           badge: '',
  //           badgeClass: '',
  //           role: [''],
  //           submenu: [
  //             {
  //               path: '/multilevel/thirdlevel/third1',
  //               title: 'third 1',
  //               iconType: '',
  //               icon: '',
  //               class: 'ml-menu3',
  //               groupTitle: false,
  //               badge: '',
  //               badgeClass: '',
  //               role: [''],
  //               submenu: [],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: '/multilevel/first3',
  //       title: 'Third',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: [''],
  //       submenu: [],
  //     },
  //   ],
  // },
];
