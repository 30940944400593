import {Injectable, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'environments/environment';
import {AuthService, User} from "@core";
import {BasePagingResponse} from "../models/basePagingResponse";
import {BasePagingRequest} from "../models/basePagingRequest";

import {BaseResponse} from "../models/baseResponse";
import { filter, map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotifyService {
  private readonly URL_NOTIFY = environment.apiUrl +'/v1/notify';
  public reqHeader: any;

  private resultDataSubject = new BehaviorSubject<any>(null);
  public result = this.resultDataSubject.asObservable();

  constructor(private httpClient: HttpClient ,private authService: AuthService) { }

  public setDataResult(data : any) {
    this.resultDataSubject.next(data);
  }

  public get getDataResultValue() {
    return this.resultDataSubject.value;
  }

  public getHeader () {
    const currentUser = this.authService.currentTokenValue;
    if (currentUser && currentUser.token) {
      this.reqHeader = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${currentUser.token}`
      });
      return  this.reqHeader;
    }
  }

  getCaseByDoctorCodeNotify(code : string): Observable<BaseResponse>  {
    return this.httpClient.get<BaseResponse>(this.URL_NOTIFY+"/getCaseByDoctorCodeNotify/"+code, { 'headers': this.getHeader () })
      .pipe(
        catchError( err => {
          return throwError( console.log(err) );
        })
      )
  }

  getPaymentNotify(): Observable<BaseResponse>  {
    return this.httpClient.get<BaseResponse>(this.URL_NOTIFY+"/getPaymentNotify", { 'headers': this.getHeader () })
      .pipe(
        catchError( err => {
          return throwError( console.log(err) );
        })
      )
  }

  getCheckTransactionNotify(): Observable<BaseResponse>  {
    return this.httpClient.get<BaseResponse>(this.URL_NOTIFY+"/getCheckTransactionNotify", { 'headers': this.getHeader () })
      .pipe(
        catchError( err => {
          return throwError( console.log(err) );
        })
      )
  }
}
