import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import {environment} from "environments/environment";
import {DOCUMENT} from "@angular/common";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{
  currentUrl!: string;

  constructor(public _router: Router , element: ElementRef
              ,@Inject(DOCUMENT) document: Document) {

    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        /* empty */
      }
      window.scrollTo(0, 0);

    });

    document.title = environment.titleIndex;
  }

}
