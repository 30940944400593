import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public languages: string[] = ['th', 'en', 'es', 'de'];

  constructor(public translate: TranslateService) {

    this.translate.setDefaultLang('th');
    // let browserLang: string;
    // translate.addLangs(this.languages);
    //
    // if (localStorage.getItem('lang')) {
    //   browserLang = localStorage.getItem('lang') as string;
    // } else {
    //   browserLang = translate.getBrowserLang() as string;
    //   localStorage.setItem('lang', 'th');
    // }
    // translate.use(browserLang.match(/th|en|es|de/) ? browserLang : 'th');
  }

  public setLanguage(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('lang', lang);
  }
}
