/* eslint-disable @typescript-eslint/no-unused-vars */
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES } from './sidebar-items';
import { RouteInfo } from './sidebar.metadata';
import { AuthService, Role } from '@core';
import { NotifyService } from 'app/pages/service/notify.service';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems!: RouteInfo[];
  public innerHeight?: number;
  public bodyTag!: HTMLElement;
  listMaxHeight?: string;
  listMaxWidth?: string;
  userFullName?: string;
  userImg?: string;
  userType?: string;
  headerHeight = 60;
  currentRoute?: string;
  routerObj;

  private stompClient: any;
  private CHANNEL = '/topic/notify/';
  // private ENDPOINT = 'http://localhost:9090/clinic-service/socket';
  private ENDPOINT = environment.apiUrl+'/socket';
  private isConnected = false;
  public receiveGetCaseByDoctorCodeNotify = 0;
  public receiveGetPaymentNotify = 0;
  public receiveGetCheckTransactionNotify = 0;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private notifyService: NotifyService,
    private router: Router
  ) {
    this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  windowResizecall() {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  callToggleMenu(event: Event, length: number) {
    if (length > 0) {
      const parentElement = (event.target as HTMLInputElement).closest('li');
      const activeClass = parentElement?.classList.contains('active');

      if (activeClass) {
        this.renderer.removeClass(parentElement, 'active');
      } else {
        this.renderer.addClass(parentElement, 'active');
      }
    }
  }
  ngOnInit() {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.role;
      this.userFullName =
        this.authService.currentUserValue.firstName +
        ' ' +
        this.authService.currentUserValue.lastName;
      //this.userImg = this.authService.currentUserValue.img;
      this.userImg = 'assets/images/user/admin.jpg';

      this.sidebarItems = ROUTES.filter(
        (x) => x.role.indexOf(userRole) !== -1 || x.role.indexOf('All') !== -1
      );
      if (userRole === Role.Admin) {
        this.userType = Role.Admin;
      } else if (userRole === Role.Patient) {
        this.userType = Role.Patient;
      } else if (userRole === Role.Doctor) {
        this.userType = Role.Doctor;
      } else if (userRole === Role.Employee) {
        this.userType = Role.Employee;
      } else {
        this.userType = Role.Admin;
      }
    }

    // this.sidebarItems = ROUTES.filter((sidebarItem) => sidebarItem);
    this.initLeftSidebar();
    this.bodyTag = this.document.body;

    this.connectWebsocket();
    this.getCaseByDoctorCodeNotify();
    this.getPaymentNotify();
    this.getCheckTransactionNotify();
    console.log(this.authService)
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  initLeftSidebar() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }
  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime: boolean) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover() {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }

  mouseOut() {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  logout() {
    this.authService.logout().subscribe((res) => {
      if (!res.success) {
        this.router.navigate(['/authentication/signin']);
      }
    });
  }

  private connectWebsocket() {
    const ws = new SockJS(this.ENDPOINT);
    this.stompClient = Stomp.over(ws);

    this.stompClient.connect(
      {},
      () => {
        this.isConnected = true;
        this.subscribeToGlobalChat();
        console.log('ws connected');
      },
       () => {
        console.log('lost connected');
        this.reconnect();
      }
    );
  }

  private reconnect(){
    console.log('reconnected');
    this.connectWebsocket();
  }

  private subscribeToGlobalChat(){
    const currentUser: any = this.authService.currentUserValue;
    this.stompClient.subscribe(this.CHANNEL + currentUser.role, (message:any) => {
      if(currentUser.role == "Employee"){
        this.getPaymentNotify();
        this.getCheckTransactionNotify();
      }
      //some service
    });
    this.stompClient.subscribe(this.CHANNEL + currentUser.userName, (message:any) => {
      this.getCaseByDoctorCodeNotify();
    });
  }

  private async getCaseByDoctorCodeNotify(){
    const currentUser: any = this.authService.currentUserValue;
    await this.notifyService
    .getCaseByDoctorCodeNotify(currentUser.userName)
    .toPromise()
    .then(
      (res) => {
        this.receiveGetCaseByDoctorCodeNotify = Number(res);
      },
      (err) => console.log(err)
    );
  }

  private async getPaymentNotify(){
    await this.notifyService
    .getPaymentNotify()
    .toPromise()
    .then(
      (res) => {
        console.log("receiveGetPaymentNotify : " + res)
        this.receiveGetPaymentNotify = Number(res);
      },
      (err) => console.log(err)
    );
  }

  private async getCheckTransactionNotify(){
    await this.notifyService
    .getCheckTransactionNotify()
    .toPromise()
    .then(
      (res) => {
        console.log("receiveGetCheckTransactionNotify : " + res)
        this.receiveGetCheckTransactionNotify = Number(res);
      },
      (err) => console.log(err)
    );
  }
}
