import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { MaterialModule } from "./material.module";
import { FeatherIconsModule } from "./components/feather-icons/feather-icons.module";
import {NumbersOnlyDirective} from "@shared/directive/numbers-only.directive";
import {DatePickerFormatDirective} from "@shared/directive/date-picker-format.directive";
import {CharactersOnlyDirective} from "@shared/directive/characters-only.directive";
import {DecimalAmountDirective} from "@shared/directive/decimal-amount.directive";


@NgModule({
  declarations: [   NumbersOnlyDirective,CharactersOnlyDirective,DatePickerFormatDirective,DecimalAmountDirective],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    FeatherIconsModule,

    NumbersOnlyDirective,
    CharactersOnlyDirective,
    DecimalAmountDirective
  ],
})
export class SharedModule { }
